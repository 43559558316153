const formatRepData = () => {
  const reps = `firstName,middleName,lastName,suffix,formUrl,non,st_dis
  Ralph,Lee,Abraham,MD DVM,https://abraham.house.gov/zip-code-lookup?form=/contact/email,LA05,LA-05
  Alma,S.,Adams,,https://adamsforms.house.gov/contact/,NC12,NC-12
  Robert,B.,Aderholt,,https://aderholt.house.gov/contact/request-appearance,AL04,AL-04
  Pete,,Aguilar,,https://aguilar.house.gov/zip-code-lookup?form=/contact/email,CA31,CA-31
  Rick,W.,Allen,,https://allen.house.gov/contact/,GA12,GA-12
  Colin,Z.,Allred,,https://allred.house.gov/zip-code-lookup?form=%2Fcontact%2Femail-me,TX32,TX-32
  Justin,,Amash,,https://amash.house.gov/contact/email,MI03,MI-03
  Mark,E.,Amodei,,https://amodei.house.gov/email-me/,NV02,NV-02
  Kelly,,Armstrong,,https://armstrong.house.gov/contact,ND00,ND-00
  Jodey,C.,Arrington,,https://arrington.house.gov/contact/,TX19,TX-19
  Cynthia,,Axne,,https://axne.house.gov/contact,IA03,IA-03
  Brian,,Babin,,https://babin.house.gov/contact/,TX36,TX-36
  Don,,Bacon,,https://bacon.house.gov/contact/,NE02,NE-02
  James,R.,Baird,,https://baird.house.gov/contact/,IN04,IN-04
  Troy,,Balderson,,https://balderson.house.gov/contact/,OH12,OH-12
  Jim,,Banks,,https://baird.house.gov/contact/,IN03,IN-03
  Andy,,Barr,,https://barr.house.gov/email-me,KY06,KY-06
  Nanette,Diaz,Barragán,,https://barragan.house.gov/about/contact/,CA44,CA-44
  Karen,,Bass,RN,https://bass.house.gov/contact,CA37,CA-37
  Joyce,,Beatty,,https://beatty.house.gov/contact,OH03,OH-03
  Ami,,Bera,MD,https://bera.house.gov/connect-with-me/email-ami,CA07,CA-07
  Jack,,Bergman,,https://bergman.house.gov/contact/,MI01,MI-01
  Donald,S.,Beyer,Jr.,https://beyer.house.gov/contact/,VA08,VA-08
  Andy,,Biggs,,https://biggs.house.gov/contact,AZ05,AZ-05
  Gus,M.,Bilirakis,,https://bilirakis.house.gov/contact,FL12,FL-12
  Dan,,Bishop,,https://danbishop.house.gov/contact,NC09,NC-09
  Rob,,Bishop,,https://robbishop.house.gov/contact,UT01,UT-01
  Sanford,D.,Bishop,Jr.,https://bishop.house.gov/contact,GA02,GA-02
  Earl,,Blumenauer,,https://blumenauerforms.house.gov/forms/writeyourrep/,OR03,OR-03
  Lisa,,Blunt Rochester,,https://bluntrochester.house.gov/contact/,DE00,DE-00
  Suzanne,,Bonamici,,https://bonamici.house.gov/zip-code-lookup?form=/contact/email,OR01,OR-01
  Mike,,Bost,,https://bost.house.gov/zip-code-lookup?form=/contact/email,IL12,IL-12
  Brendan,F.,Boyle,,https://boyle.house.gov/contact/email,PA02,PA-02
  Kevin,,Brady,,https://kevinbrady.house.gov/contact/,TX08,TX-08
  Anthony,,Brindisi,,https://brindisi.house.gov/contact/email-me,NY22,NY-22
  Mo,,Brooks,,https://brooks.house.gov/contact-me/email-me,AL05,AL-05
  Susan,W.,Brooks,,https://susanwbrooks.house.gov/contact/email-me,IN05,IN-05
  Anthony,G.,Brown,,https://anthonybrown.house.gov/contact/,MD04,MD-04
  Julia,,Brownley,,https://juliabrownley.house.gov/contact/email-me/,CA26,CA-26
  Vern,,Buchanan,,https://buchanan.house.gov/contact/email-me,FL16,FL-16
  Ken,,Buck,,https://buck.house.gov/zip-code-lookup?form=/contact/email,CO04,CO-04
  Larry,,Bucshon,MD,https://bucshon.house.gov/,IN08,IN-08
  Ted,,Budd,,https://budd.house.gov/contact/,NC13,NC-13
  Tim,,Burchett,,https://burchett.house.gov/zip-code-lookup?form=/contact/email-me,TN02,TN-02
  Michael,C.,Burgess,MD,https://burgess.house.gov/contact/356345.htm,TX26,TX-26
  Cheri,,Bustos,,https://bustos.house.gov/contact/,IL17,IL-17
  G.,K.,Butterfield,,https://butterfield.house.gov/zip-code-lookup?form=/contact/email-me,NC01,NC-01
  Bradley,,Byrne,,https://byrne.house.gov/contact,AL01,AL-01
  Ken,,Calvert,,https://calvert.house.gov/contact/email?submit=Go+To+ZIP+Code+Authentication,CA42,CA-42
  Salud,O.,Carbajal,,https://carbajal.house.gov/contact/,CA24,CA-24
  Tony,,Cárdenas,,https://cardenas.house.gov/contact-me/email-me,CA29,CA-29
  André,,Carson,,https://carson.house.gov/zip-code-lookup?form=/contact/email-me,IN07,IN-07
  Earl,"L. ""Buddy""",Carter,,https://buddycarter.house.gov/contact/,GA01,GA-01
  John,R.,Carter,,https://carter.house.gov/email-john2/,TX31,TX-31
  Matt,,Cartwright,,https://cartwright.house.gov/contact/email-me,PA08,PA-08
  Ed,,Case,,https://case.house.gov/contact/,HI01,HI-01
  Sean,,Casten,,https://casten.house.gov/zip-code-lookup?form=/contact/email-me,IL06,IL-06
  Kathy,,Castor,,https://castor.house.gov/contact/contactform.htm,FL14,FL-14
  Joaquin,,Castro,,https://castro.house.gov/contact,TX20,TX-20
  Steve,,Chabot,,https://chabot.house.gov/contact/,OH01,OH-01
  Liz,,Cheney,,https://cheneyforms.house.gov/contact/,WY00,WY-00
  Judy,,Chu,,https://chu.house.gov/zip-code-lookup?form=/contact/email,CA27,CA-27
  David,N.,Cicilline,,https://cicilline.house.gov/contact-me/email-me,RI01,RI-01
  Gilbert,Ray,Cisneros,Jr.,https://cisneros.house.gov/zip-code-lookup?form=/contact/email-me,CA39,CA-39
  Katherine,M.,Clark,,https://katherineclark.house.gov/email-me,MA05,MA-05
  Yvette,D.,Clarke,,https://clarke.house.gov/contact/email/,NY09,NY-09
  Wm.,Lacy,Clay,,https://lacyclay.house.gov/contact/email/,MO01,MO-01
  Emanuel,,Cleaver,,https://cleaver.house.gov/contact/email-me,MO05,MO-05
  Ben,,Cline,,https://cline.house.gov/zip-code-lookup?form=/contact/email-me,VA06,VA-06
  Michael,,Cloud,,https://cloud.house.gov/contact/,TX27,TX-27
  James,E.,Clyburn,,https://clyburn.house.gov/contact-me/email-me,SC06,SC-06
  Steve,,Cohen,,https://cohen.house.gov/zip-code-lookup?form=/contact/email,TN09,TN-09
  Tom,,Cole,,https://cole.house.gov/contact/email,OK04,OK-04
  Doug,,Collins,,https://dougcollins.house.gov/contact-doug,GA09,GA-09
  James,,Comer,,https://comer.house.gov/zip-code-lookup?form=/contact/email,KY01,KY-01
  K.,Michael,Conaway,,https://conaway.house.gov/contact/,TX11,TX-11
  Gerald,E.,Connolly,,https://connolly.house.gov/contact/zip-auth.htm,VA11,VA-11
  Paul,,Cook,,https://cookforms.house.gov/contact/,CA08,CA-08
  Jim,,Cooper,,https://cooper.house.gov/zip-code-lookup?form=/contact/email,TN05,TN-05
  J.,Luis,Correa,,https://correa.house.gov/contact,CA46,CA-46
  Jim,,Costa,,https://costa.house.gov/zip-code-lookup?form=/contact/email-me,CA16,CA-16
  Joe,,Courtney,,https://courtney.house.gov/zip-code-lookup?form=/contact/email-me,CT02,CT-02
  TJ,,Cox,,https://cox.house.gov/zip-code-lookup?form=/contact/email-me,CA21,CA-21
  Angie,,Craig,,https://craig.house.gov/zip-code-lookup?form=/contact/email-me,MN02,MN-02
  Eric,"A. ""Rick""",Crawford,,https://crawford.house.gov/contact/contact-form.htm,AR01,AR-01
  Dan,,Crenshaw,,https://crenshaw.house.gov/contact/,TX02,TX-02
  Charlie,,Crist,,https://crist.house.gov/contact/,FL13,FL-13
  Jason,,Crow,,https://crow.house.gov/contact/email-me,CO06,CO-06
  Henry,,Cuellar,,https://cuellar.house.gov/contact/sendmeanemail.htm,TX28,TX-28
  Joe,,Cunningham,,https://cunningham.house.gov/contact,SC01,SC-01
  John,R.,Curtis,,https://curtis.house.gov/email/,UT03,UT-03
  Sharice,,Davids,,https://davids.house.gov/contact/email-me,KS03,KS-03
  Warren,,Davidson,,https://davidson.house.gov/contact/,OH08,OH-08
  Danny,K.,Davis,,https://davis.house.gov/email-me/,IL07,IL-07
  Rodney,,Davis,,https://rodneydavis.house.gov/contact/,IL13,IL-13
  Susan,A.,Davis,,https://susandavis.house.gov/contact/,CA53,CA-53
  Madeleine,,Dean,,https://dean.house.gov/zip-code-lookup?form=/contact/email-me,PA04,PA-04
  Peter,A.,DeFazio,,https://defazio.house.gov/contact/email-peter,OR04,OR-04
  Diana,,DeGette,,https://degette.house.gov/contact/e-mail-diana,CO01,CO-01
  Rosa,L.,DeLauro,,https://delauro.house.gov/contact/email,CT03,CT-03
  Suzan,K.,DelBene,,https://delbene.house.gov/contact/,WA01,WA-01
  Antonio,,Delgado,,https://delgado.house.gov/contact/email-me,NY19,NY-19
  Val,Butler,Demings,,https://demingsforms.house.gov/contact/,FL10,FL-10
  Mark,,DeSaulnier,,https://desaulnier.house.gov/contact/email,CA11,CA-11
  Scott,,DesJarlais,MD,https://desjarlais.house.gov/contact/email-me,TN04,TN-04
  Theodore,E.,Deutch,,https://teddeutch.house.gov/forms/writeyourrep/,FL22,FL-22
  Mario,,Diaz-Balart,,https://mariodiazbalart.house.gov/contact-mario,FL25,FL-25
  Debbie,,Dingell,,https://debbiedingell.house.gov/contact/,MI12,MI-12
  Lloyd,,Doggett,,https://doggett.house.gov/contact,TX35,TX-35
  Michael,F.,Doyle,,https://doyle.house.gov/zip-code-lookup?form=/contact/email-me,PA18,PA-18
  Jeff,,Duncan,,https://jeffduncan.house.gov/zip-code-lookup?form=/contact/email-me,SC03,SC-03
  Neal,P.,Dunn,MD,https://dunn.house.gov/contact,FL02,FL-02
  Tom,,Emmer,,https://emmer.house.gov/contact,MN06,MN-06
  Eliot,L.,Engel,,https://engel.house.gov/email/,NY16,NY-16
  Veronica,,Escobar,,https://escobar.house.gov/contact/,TX16,TX-16
  Anna,G.,Eshoo,,https://eshoo.house.gov/contact/email-me,CA18,CA-18
  Adriano,,Espaillat,,https://espaillat.house.gov/contact/email,NY13,NY-13
  Ron,,Estes,,https://estes.house.gov/contact/,KS04,KS-04
  Dwight,,Evans,,https://evans.house.gov/contact,PA03,PA-03
  A.,Drew,Ferguson,IV,https://ferguson.house.gov/contact/,GA03,GA-03
  Abby,,Finkenauer,,https://finkenauer.house.gov/zip-code-lookup?form=/contact/email-me,IA01,IA-01
  Brian,K.,Fitzpatrick,,https://fitzpatrick.house.gov/contact-me,PA01,PA-01
  Charles,"J. ""Chuck""",Fleischmann,,https://fleischmann.house.gov/zip-code-lookup?form=/contact/email,TN03,TN-03
  Lizzie,,Fletcher,,https://fletcher.house.gov/contact/,TX07,TX-07
  Bill,,Flores,,https://flores.house.gov/contact/,TX17,TX-17
  Jeff,,Fortenberry,,https://fortenberry.house.gov/zip-code-lookup?form=/contact-me/share-your-opinion,NE01,NE-01
  Bill,,Foster,,https://foster.house.gov/contact/email-me,IL11,IL-11
  Virginia,,Foxx,,https://foxx.house.gov/connect/,NC05,NC-05
  Lois,,Frankel,,https://frankel.house.gov/contact/,FL21,FL-21
  Marcia,L.,Fudge,,https://fudge.house.gov/email-me,OH11,OH-11
  Russ,,Fulcher,,https://fulcher.house.gov/contact/?p=email-me,ID01,ID-01
  Tulsi,,Gabbard,,https://gabbard.house.gov/connect/share-your-ideas,HI02,HI-02
  Matt,,Gaetz,,https://gaetz.house.gov/contact,FL01,FL-01
  Mike,,Gallagher,,https://gallagher.house.gov/zip-code-lookup?form=/contact/email,WI08,WI-08
  Ruben,,Gallego,,https://rubengallego.house.gov/contact/email,AZ07,AZ-07
  John,,Garamendi,,https://garamendi.house.gov/contact/email,CA03,CA-03
  Sylvia,R.,Garcia,,https://sylviagarcia.house.gov/zip-code-lookup?form=/contact/email-me,TX29,TX-29
  Jesús,"G. ""Chuy""",García,,https://chuygarcia.house.gov/zip-code-lookup?form=/contact/email-me,IL04,IL-04
  Greg,,Gianforte,,https://gianforte.house.gov/contact/email,MT00,MT-00
  Bob,,Gibbs,,https://gibbs.house.gov/contact/email,OH07,OH-07
  Louie,,Gohmert,,https://gohmert.house.gov/contact/,TX01,TX-01
  Jared,F.,Golden,,https://golden.house.gov/contact/email-me,ME02,ME-02
  Jimmy,,Gomez,,https://gomez.house.gov/contact/,CA34,CA-34
  Anthony,,Gonzalez,,https://anthonygonzalez.house.gov/contact/,OH16,OH-16
  Vicente,,Gonzalez,,https://gonzalez.house.gov/contact/email,TX15,TX-15
  Jenniffer,,González-Colón,,https://gonzalez-colon.house.gov/contact,PR00,PR-00
  Lance,,Gooden,,https://gooden.house.gov/zip-code-lookup?form=/contact/email-me,TX05,TX-05
  Paul,A.,Gosar,DDS,https://gosar.house.gov/contact/,AZ04,AZ-04
  Josh,,Gottheimer,,https://gottheimer.house.gov/contact/,NJ05,NJ-05
  Kay,,Granger,,https://kaygranger.house.gov/email,TX12,TX-12
  Garret,,Graves,,https://garretgraves.house.gov/contact/email,LA06,LA-06
  Sam,,Graves,,https://graves.house.gov/contact/email-me,MO06,MO-06
  Tom,,Graves,,https://tomgraves.house.gov/contact/,GA14,GA-14
  Al,,Green,,https://algreen.house.gov/contact-me/email-me,TX09,TX-09
  Mark,E.,Green,MD,https://markgreen.house.gov/email-me,TN07,TN-07
  H.,Morgan,Griffith,,https://morgangriffith.house.gov/contact/contactform.htm,VA09,VA-09
  Raúl,M.,Grijalva,,https://grijalva.house.gov/email-raul,AZ03,AZ-03
  Glenn,,Grothman,,https://grothman.house.gov/contact/,WI06,WI-06
  Michael,,Guest,,https://guest.house.gov/zip-code-lookup?form=/contact/email-me,MS03,MS-03
  Brett,,Guthrie,,https://guthrie.house.gov/contact/,KY02,KY-02
  Debra,A.,Haaland,,https://haaland.house.gov/zip-code-lookup?form=/contact/email-me,NM01,NM-01
  Jim,,Hagedorn,,https://hagedorn.house.gov/zip-code-lookup?form=/contact/email-me,MN01,MN-01
  Josh,,Harder,,https://harder.house.gov/zip-code-lookup?form=/contact/email-me,CA10,CA-10
  Andy,,Harris,MD,https://harris.house.gov/contact/email,MD01,MD-01
  Vicky,,Hartzler,,https://hartzler.house.gov/contact/email,MO04,MO-04
  Alcee,L.,Hastings,,https://alceehastings.house.gov/contact/,FL20,FL-20
  Jahana,,Hayes,,https://hayes.house.gov/contact/email-me,CT05,CT-05
  Denny,,Heck,,https://dennyheck.house.gov/contact/email-me,WA10,WA-10
  Kevin,,Hern,,https://hern.house.gov/contact/,OK01,OK-01
  Jaime,,Herrera Beutler,,https://jhb.house.gov/contact/,WA03,WA-03
  Jody,B.,Hice,,https://hice.house.gov/contact/,GA10,GA-10
  Brian,,Higgins,,https://higgins.house.gov/contact/email-brian,NY26,NY-26
  Clay,,Higgins,,https://clayhiggins.house.gov/zip-code-lookup?form=/contact/email,LA03,LA-03
  J.,French,Hill,,https://hill.house.gov/contact/,AR02,AR-02
  James,A.,Himes,,https://himes.house.gov/contact/email,CT04,CT-04
  George,,Holding,,https://holding.house.gov/contact/,NC02,NC-02
  Trey,,Hollingsworth,,https://hollingsworth.house.gov/contact/,IN09,IN-09
  Kendra,S.,Horn,,https://horn.house.gov/contact/,OK05,OK-05
  Steven,,Horsford,,https://horsford.house.gov/contact,NV04,NV-04
  Chrissy,,Houlahan,,https://houlahan.house.gov/contact/,PA06,PA-06
  Steny,H.,Hoyer,,https://hoyer.house.gov/help/contact,MD05,MD-05
  Richard,,Hudson,,https://hudson.house.gov/email-me/,NC08,NC-08
  Jared,,Huffman,,https://huffman.house.gov/contact/email-me,CA02,CA-02
  Bill,,Huizenga,,https://huizenga.house.gov/contact/,MI02,MI-02
  Will,,Hurd,,https://hurd.house.gov/contact/email,TX23,TX-23
  Sheila,,Jackson Lee,,https://jacksonlee.house.gov/contact/email-me,TX18,TX-18
  Pramila,,Jayapal,,https://jayapal.house.gov/contact/email/,WA07,WA-07
  Hakeem,S.,Jeffries,,https://jeffries.house.gov/contact/email-me,NY08,NY-08
  Bill,,Johnson,,https://billjohnson.house.gov/contact/,OH06,OH-06
  Dusty,,Johnson,,https://dustyjohnson.house.gov/zip-code-lookup?form=/contact/email-me,SD00,SD-00
  Eddie,Bernice,Johnson,RN,https://ebjohnson.house.gov/contact-us/share-your-ideas,TX30,TX-30
  Henry,"C. ""Hank""",Johnson,Jr.,https://hankjohnson.house.gov/zip-code-lookup?form=/contact/email,GA04,GA-04
  Mike,,Johnson,,https://mikejohnson.house.gov/contact/email,LA04,LA-04
  Jim,,Jordan,,https://jordan.house.gov/contact/,OH04,OH-04
  David,P.,Joyce,,https://joyce.house.gov/email-me/,OH14,OH-14
  John,,Joyce,MD,https://johnjoyce.house.gov/zip-code-lookup?form=/contact/email-me,PA13,PA-13
  Marcy,,Kaptur,,https://kaptur.house.gov/contact/email,OH09,OH-09
  John,,Katko,,https://katko.house.gov/contact/email,NY24,NY-24
  William,R.,Keating,,https://keating.house.gov/contact/email,MA09,MA-09
  Fred,,Keller,,https://keller.house.gov/zip-code-lookup?form=/contact/email-me,PA12,PA-12
  Mike,,Kelly,,https://kelly.house.gov/contact-me/email-me,PA16,PA-16
  Robin,L.,Kelly,,https://robinkelly.house.gov/contact/email-me,IL02,IL-02
  Trent,,Kelly,,https://trentkelly.house.gov/contact/,MS01,MS-01
  Joseph,P.,Kennedy,III,https://kennedy.house.gov/contact/email-me,MA04,MA-04
  Ro,,Khanna,,https://khanna.house.gov/contact/email,CA17,CA-17
  Daniel,T.,Kildee,,https://dankildee.house.gov/zip-code-lookup?form=/contact/email,MI05,MI-05
  Derek,,Kilmer,,https://kilmer.house.gov/contact/email-me,WA06,WA-06
  Andy,,Kim,,https://kim.house.gov/zip-code-lookup?form=/contact/email-me,NJ03,NJ-03
  Ron,,Kind,,https://kind.house.gov/contact/email,WI03,WI-03
  Peter,T.,King,,https://peteking.house.gov/contact/email-me,NY02,NY-02
  Steve,,King,,https://steveking.house.gov/contact/email-me,IA04,IA-04
  Adam,,Kinzinger,,https://kinzinger.house.gov/contact/,IL16,IL-16
  Ann,,Kirkpatrick,,https://kirkpatrick.house.gov/contact/email-me/,AZ02,AZ-02
  Raja,,Krishnamoorthi,,https://krishnamoorthi.house.gov/contact/email,IL08,IL-08
  Ann,M.,Kuster,,https://kuster.house.gov/contact/email-me,NH02,NH-02
  David,,Kustoff,,https://kustoff.house.gov/contact/email,TN08,TN-08
  Darin,,LaHood,,https://lahood.house.gov/contact/email,IL18,IL-18
  Doug,,LaMalfa,,https://lamalfa.house.gov/contact,CA01,CA-01
  Conor,,Lamb,,https://lamb.house.gov/contact/email,PA17,PA-17
  Doug,,Lamborn,,https://lamborn.house.gov/zip-code-lookup?form=/contact/email-me,CO05,CO-05
  James,R.,Langevin,,https://langevin.house.gov/contact-me/email-me,RI02,RI-02
  Rick,,Larsen,,https://larsen.house.gov/contact/,WA02,WA-02
  John,B.,Larson,,https://larson.house.gov/contact/email,CT01,CT-01
  Robert,E.,Latta,,https://latta.house.gov/contact/,OH05,OH-05
  Brenda,L.,Lawrence,,https://lawrence.house.gov/contact/email,MI14,MI-14
  Al,,Lawson,Jr.,https://lawson.house.gov/contact/email,FL05,FL-05
  Barbara,,Lee,,https://lee.house.gov/contact/email-me,CA13,CA-13
  Susie,,Lee,,https://susielee.house.gov/zip-code-lookup?form=/contact/email-me,NV03,NV-03
  Debbie,,Lesko,,https://lesko.house.gov/contact/,AZ08,AZ-08
  Andy,,Levin,,https://andylevin.house.gov/zip-code-lookup?form=/contact/email-me,MI09,MI-09
  Mike,,Levin,,https://mikelevin.house.gov/zip-code-lookup?form=/contact/email-me,CA49,CA-49
  John,,Lewis,,https://johnlewis.house.gov/contact-me/email,GA05,GA-05
  Ted,,Lieu,,https://lieu.house.gov/contact/email,CA33,CA-33
  Daniel,,Lipinski,,https://lipinski.house.gov/contact-form/,IL03,IL-03
  David,,Loebsack,,https://loebsack.house.gov/forms/writeyourrep/,IA02,IA-02
  Zoe,,Lofgren,,https://lofgren.house.gov/contact/email,CA19,CA-19
  Billy,,Long,,https://long.house.gov/contact/,MO07,MO-07
  Barry,,Loudermilk,,https://loudermilk.house.gov/contact/,GA11,GA-11
  Alan,S.,Lowenthal,,https://lowenthal.house.gov/zip-code-lookup?form=/contact/email-me,CA47,CA-47
  Nita,M.,Lowey,,https://lowey.house.gov/contact/email,NY17,NY-17
  Frank,D.,Lucas,,https://lucas.house.gov/zip-code-lookup?form=/contact/email-me,OK03,OK-03
  Blaine,,Luetkemeyer,,https://luetkemeyer.house.gov/contact/,MO03,MO-03
  Ben,Ray,Luján,,https://lujan.house.gov/contact/mail,NM03,NM-03
  Elaine,G.,Luria,,https://luria.house.gov/zip-code-lookup?form=/contact/email-me,VA02,VA-02
  Stephen,F.,Lynch,,https://lynch.house.gov/contact-me/email-me,MA08,MA-08
  Tom,,Malinowski,,https://malinowski.house.gov/contact/send-me-email,NJ07,NJ-07
  Carolyn,B.,Maloney,,https://maloney.house.gov/contact-carolyn/email-me,NY12,NY-12
  Sean,Patrick,Maloney,,https://seanmaloney.house.gov/contact/email-me,NY18,NY-18
  Kenny,,Marchant,,https://marchant.house.gov/contact/,TX24,TX-24
  Roger,W.,Marshall,MD,https://marshall.house.gov/zip-code-lookup?form=/contact/email-me,KS01,KS-01
  Thomas,,Massie,,https://massieforms.house.gov/contact/,KY04,KY-04
  Brian,J.,Mast,,https://mast.house.gov/email,FL18,FL-18
  Doris,O.,Matsui,,https://matsui.house.gov/contact/,CA06,CA-06
  Ben,,McAdams,,https://mcadams.house.gov/forms/writeyourrep/,UT04,UT-04
  Lucy,,McBath,,https://mcbath.house.gov/email-me,GA06,GA-06
  Kevin,,McCarthy,,https://kevinmccarthy.house.gov/contact/email-me,CA23,CA-23
  Michael,T.,McCaul,,https://mccaul.house.gov/zip-code-lookup?form=/contact/email-me,TX10,TX-10
  Tom,,McClintock,,https://mcclintock.house.gov/contact/email-me,CA04,CA-04
  Betty,,McCollum,,https://mccollum.house.gov/contact/email,MN04,MN-04
  A.,Donald,McEachin,,https://mceachin.house.gov/contact/email,VA04,VA-04
  James,P.,McGovern,,https://mcgovern.house.gov/contact/zip-auth.htm,MA02,MA-02
  Patrick,T.,McHenry,,https://mchenry.house.gov/contact/zipauth.htm,NC10,NC-10
  David,B.,McKinley,,https://mckinley.house.gov/contact/,WV01,WV-01
  Jerry,,McNerney,,https://mcnerney.house.gov/contact,CA09,CA-09
  Gregory,W.,Meeks,,https://meeks.house.gov/contact/email,NY05,NY-05
  Grace,,Meng,,https://meng.house.gov/contact/email-me,NY06,NY-06
  Daniel,,Meuser,,https://meuser.house.gov/zip-code-lookup?form=/contact/email-me,PA09,PA-09
  Kweisi ,,Mfume,,https://mfume.house.gov/contact/email-me,MD07,MD-07
  Carol,D.,Miller,,https://miller.house.gov/zip-code-lookup?form=/contact/email-me,WV03,WV-03
  Paul,,Mitchell,,https://mitchell.house.gov/contact/email,MI10,MI-10
  John,R.,Moolenaar,,https://moolenaar.house.gov/contact/email,MI04,MI-04
  Alexander,X.,Mooney,,https://mooney.house.gov/contact/email,WV02,WV-02
  Gwen,,Moore,,https://gwenmoore.house.gov/contact/,WI04,WI-04
  Joseph,D.,Morelle,,https://morelleforms.house.gov/contact/,NY25,NY-25
  Seth,,Moulton,,https://moulton.house.gov/contact/,MA06,MA-06
  Debbie,,Mucarsel-Powell,,https://mucarsel-powell.house.gov/contact/,FL26,FL-26
  Markwayne,,Mullin,,https://mullin.house.gov/contact/,OK02,OK-02
  Gregory,F.,Murphy,,https://gregmurphy.house.gov/contact/email,NC03,NC-03
  Stephanie,N.,Murphy,,https://murphy.house.gov/contact/,FL07,FL-07
  Jerrold,,Nadler,,https://nadler.house.gov/contact/,NY10,NY-10
  Grace,F.,Napolitano,,https://napolitano.house.gov/contact/email,CA32,CA-32
  Richard,E.,Neal,,https://neal.house.gov/contact,MA01,MA-01
  Joe,,Neguse,,https://neguse.house.gov/contact,CO02,CO-02
  Dan,,Newhouse,,https://newhouse.house.gov/contact/email,WA04,WA-04
  Donald,,Norcross,,https://norcross.house.gov/contact/email-me,NJ01,NJ-01
  Ralph,,Norman,,https://norman.house.gov/contact/,SC05,SC-05
  Eleanor,Holmes,Norton,,https://norton.house.gov/zip-code-lookup?form=/contact/email-me,DC00,DC-00
  Devin,,Nunes,,https://nunes.house.gov/contactform/,CA22,CA-22
  Tom,,O'Halleran,,https://ohalleran.house.gov/zip-code-lookup?form=/contact/email,AZ01,AZ-01
  Alexandria,,Ocasio-Cortez,,https://ocasio-cortez.house.gov/contact,NY14,NY-14
  ,,Office of the Eleventh Congressional District of North Carolina,,,NC11,NC-11
  ,,Office of the Fiftieth Congressional District of California,,,CA50,CA-50
  ,,Office of the Seventh Congressional District of Wisconsin,,,WI07,WI-07
  ,,Office of the Twenty-Fifth Congressional District of California,,,CA25,CA-25
  ,,Office of the Twenty-Seventh Congressional District of New York,,,NY27,NY-27
  Pete,,Olson,,https://olson.house.gov/contact/email-me,TX22,TX-22
  Ilhan,,Omar,,https://omar.house.gov/zip-code-lookup?form=/contact/email-me,MN05,MN-05
  Steven,M.,Palazzo,,https://palazzo.house.gov/contact/,MS04,MS-04
  Frank,,Pallone,Jr.,https://pallone.house.gov/contact,NJ06,NJ-06
  Gary,J.,Palmer,,https://palmer.house.gov/contact,AL06,AL-06
  Jimmy,,Panetta,,https://panetta.house.gov/contact,CA20,CA-20
  Chris,,Pappas,,https://pappas.house.gov/contact/email-me,NH01,NH-01
  Bill,,Pascrell,Jr.,https://pascrell.house.gov/contact/,NJ09,NJ-09
  Donald,M.,Payne,Jr.,https://payne.house.gov/zip-code-lookup?form=/contact/email-me,NJ10,NJ-10
  Nancy,,Pelosi,,https://pelosi.house.gov/contact-me/email-me,CA12,CA-12
  Greg,,Pence,,https://pence.house.gov/contact/email-me,IN06,IN-06
  Ed,,Perlmutter,,https://perlmutter.house.gov/forms/writeyourrep/,CO07,CO-07
  Scott,,Perry,,https://perry.house.gov/forms/writeyourrep/default.aspx,PA10,PA-10
  Scott,H.,Peters,,https://scottpeters.house.gov/contact,CA52,CA-52
  Collin,C.,Peterson,,https://collinpeterson.house.gov/zip-code-lookup?form=/contact/email-me,MN07,MN-07
  Dean,,Phillips,,https://phillips.house.gov/zip-code-lookup?form=/contact/email-me,MN03,MN-03
  Chellie,,Pingree,,https://pingree.house.gov/contact/contactform.htm,ME01,ME-01
  Stacey,E.,Plaskett,,https://plaskett.house.gov/contact/contactform.htm,VI00,VI-00
  Mark,,Pocan,,https://pocan.house.gov/contact/share-your-opinion,WI02,WI-02
  Katie,,Porter,,https://porter.house.gov/contact/,CA45,CA-45
  Bill,,Posey,,https://posey.house.gov/forms/writeyourrep/,FL08,FL-08
  Ayanna,,Pressley,,https://pressley.house.gov/zip-code-lookup?form=/contact/email-me,MA07,MA-07
  David,E.,Price,,https://priceforms.house.gov/contact/,NC04,NC-04
  Mike,,Quigley,,https://quigleyforms.house.gov/forms/writeyourrep/,IL05,IL-05
  Aumua Amata,Coleman,Radewagen,,https://radewagen.house.gov/zip-code-lookup?form=/contact/email-me,AS00,AS-00
  Jamie,,Raskin,,https://raskin.house.gov/contact/email,MD08,MD-08
  John,,Ratcliffe,,https://ratcliffe.house.gov/contact/,TX04,TX-04
  Tom,,Reed,,https://reed.house.gov/contact/,NY23,NY-23
  Guy,,Reschenthaler,,https://reschenthaler.house.gov/zip-code-lookup?form=/contact/email-me,PA14,PA-14
  Kathleen,M.,Rice,,https://kathleenrice.house.gov/contact/,NY04,NY-04
  Tom,,Rice,,https://tomriceforms.house.gov/contact/,SC07,SC-07
  Cedric,L.,Richmond,,https://richmond.house.gov/contact-cedric/email-me,LA02,LA-02
  Denver,,Riggleman,,https://riggleman.house.gov/zip-code-lookup?form=/contact/email-me,VA05,VA-05
  Martha,,Roby,,https://roby.house.gov/contact/email-representative-roby,AL02,AL-02
  Cathy,McMorris,Rodgers,,https://mcmorris.house.gov/contact/,WA05,WA-05
  David,P.,Roe,MD,https://roe.house.gov/contact/,TN01,TN-01
  Harold,,Rogers,,https://halrogers.house.gov/email,KY05,KY-05
  Mike,,Rogers,,https://mikerogers.house.gov/contact/,AL03,AL-03
  Francis,,Rooney,,https://francisrooney.house.gov/contact/,FL19,FL-19
  John,W.,Rose,,https://johnrose.house.gov/zip-code-lookup?form=/contact/email-me,TN06,TN-06
  Max,,Rose,,https://maxrose.house.gov/contact/,NY11,NY-11
  Harley,,Rouda,,https://rouda.house.gov/zip-code-lookup?form=/contact/email-me,CA48,CA-48
  David,,Rouzer,,https://rouzer.house.gov/email,NC07,NC-07
  Chip,,Roy,,https://roy.house.gov/zip-code-lookup?form=/contact/email-me,TX21,TX-21
  Lucille,,Roybal-Allard,,https://roybal-allard.house.gov/contact/,CA40,CA-40
  Raul,,Ruiz,MD,https://ruiz.house.gov/contact,CA36,CA-36
  C.,A. Dutch,Ruppersberger,,https://ruppersberger.house.gov/contact-dutch/email-dutch,MD02,MD-02
  Bobby,L.,Rush,,https://rush.house.gov/contact/legislative-issues,IL01,IL-01
  John,H.,Rutherford,,https://rutherford.house.gov/contact/email,FL04,FL-04
  Tim,,Ryan,,https://timryanforms.house.gov/contact/,OH13,OH-13
  Gregorio,Kilili Camacho,Sablan,,https://sablan.house.gov/contact-me/email-me-zip-authenticated,MP00,MP-00
  Michael,F. Q.,San Nicolas,,https://sannicolas.house.gov/zip-code-lookup?form=/contact/email-me,GU00,GU-00
  Linda,T.,Sánchez,,https://lindasanchez.house.gov/contact/email,CA38,CA-38
  John,P.,Sarbanes,,https://sarbanes.house.gov/zip-code-lookup?form=/contact/email-me-bills,MD03,MD-03
  Steve,,Scalise,,https://scaliseforms.house.gov/contact/,LA01,LA-01
  Mary,Gay,Scanlon,,https://scanlon.house.gov/contact/,PA05,PA-05
  Janice,D.,Schakowsky,,https://schakowsky.house.gov/zip-code-lookup?form=/contact/email-me,IL09,IL-09
  Adam,B.,Schiff,,https://schiff.house.gov/contact,CA28,CA-28
  Bradley,Scott,Schneider,,https://schneider.house.gov/zip-code-lookup?form=/contact/email,IL10,IL-10
  Kurt,,Schrader,,https://schrader.house.gov/contact/,OR05,OR-05
  Kim,,Schrier,MD,https://schrier.house.gov/zip-code-lookup?form=/contact/email-me,WA08,WA-08
  David,,Schweikert,,https://schweikert.house.gov/contact/email,AZ06,AZ-06
  Austin,,Scott,,https://austinscott.house.gov/contact/email,GA08,GA-08
  David,,Scott,,https://davidscott.house.gov/contact/contactform.htm,GA13,GA-13
  Robert,"C. ""Bobby""",Scott,,https://bobbyscott.house.gov/zip-code-lookup?form=/contact/email-me,VA03,VA-03
  F.,James,Sensenbrenner,Jr.,https://sensenbrenner.house.gov/contact,WI05,WI-05
  José,E.,Serrano,,https://serrano.house.gov/contact/email-me,NY15,NY-15
  Terri,A.,Sewell,,https://sewell.house.gov/contact/email-me,AL07,AL-07
  Donna,E.,Shalala,,https://shalala.house.gov/contact/,FL27,FL-27
  Brad,,Sherman,,https://sherman.house.gov/contact/federal-issues-questionnaire,CA30,CA-30
  Mikie,,Sherrill,,https://sherrill.house.gov/contact/email-me,NJ11,NJ-11
  John,,Shimkus,,https://shimkus.house.gov/contact,IL15,IL-15
  Michael,K.,Simpson,DDS,https://simpson.house.gov/contact/,ID02,ID-02
  Albio,,Sires,,https://sires.house.gov/contact/email,NJ08,NJ-08
  Elissa,,Slotkin,,https://slotkin.house.gov/zip-code-lookup?form=/contact/email-me,MI08,MI-08
  Adam,,Smith,,https://adamsmith.house.gov/email-me,WA09,WA-09
  Adrian,,Smith,,https://adriansmith.house.gov/contact,NE03,NE-03
  Christopher,H.,Smith,,https://chrissmith.house.gov/contact/zipauth.htm,NJ04,NJ-04
  Jason,,Smith,,https://jasonsmith.house.gov/contact/,MO08,MO-08
  Lloyd,,Smucker,,https://smucker.house.gov/contact,PA11,PA-11
  Darren,,Soto,,https://soto.house.gov/zip-code-lookup?form=/contact/email,FL09,FL-09
  Abigail,Davis,Spanberger,,https://spanberger.house.gov/contact/,VA07,VA-07
  Ross,,Spano,,https://spano.house.gov/contact/,FL15,FL-15
  Jackie,,Speier,,https://speier.house.gov/email-jackie,CA14,CA-14
  Greg,,Stanton,,https://stanton.house.gov/contact/email-me,AZ09,AZ-09
  Pete,,Stauber,,https://stauber.house.gov/contact/email-me,MN08,MN-08
  Elise,M.,Stefanik,,https://stefanik.house.gov/contact,NY21,NY-21
  Bryan,,Steil,,https://steil.house.gov/zip-code-lookup?form=/contact/email-me,WI01,WI-01
  W.,Gregory,Steube,,https://steube.house.gov/contact/email-me,FL17,FL-17
  Haley,M.,Stevens,,https://stevens.house.gov/zip-code-lookup?form=/contact/email-me,MI11,MI-11
  Chris,,Stewart,,https://stewartforms.house.gov/contact/,UT02,UT-02
  Steve,,Stivers,,https://stivers.house.gov/contact/,OH15,OH-15
  Thomas,R.,Suozzi,,https://suozzi.house.gov/contact,NY03,NY-03
  Eric,,Swalwell,,https://swalwell.house.gov/contact,CA15,CA-15
  Mark,,Takano,,https://takano.house.gov/contact,CA41,CA-41
  Van,,Taylor,,https://vantaylor.house.gov/contact/,TX03,TX-03
  Bennie,G.,Thompson,,https://benniethompson.house.gov/zip-code-lookup?form=/contact/email,MS02,MS-02
  Glenn,,Thompson,,https://thompson.house.gov/contact/email,PA15,PA-15
  Mike,,Thompson,,https://mikethompsonforms.house.gov/contact/,CA05,CA-05
  Mac,,Thornberry,,https://thornberry.house.gov/contact/,TX13,TX-13
  William,R.,Timmons,IV,https://timmons.house.gov/contact/email-me,SC04,SC-04
  Scott,R.,Tipton,,https://tipton.house.gov/contact,CO03,CO-03
  Dina,,Titus,,https://titus.house.gov/contact/email-me,NV01,NV-01
  Rashida,,Tlaib,,https://tlaib.house.gov/zip-code-lookup?form=/contact/email-me,MI13,MI-13
  Paul,,Tonko,,https://tonko.house.gov/contact/,NY20,NY-20
  Norma,J.,Torres,,https://torres.house.gov/contact,CA35,CA-35
  Xochitl,,Torres Small,,https://torressmall.house.gov/contact/emailme,NM02,NM-02
  Lori,,Trahan,,https://trahan.house.gov/contact/write-your-rep.htm,MA03,MA-03
  David,J.,Trone,,https://trone.house.gov/zip-code-lookup?form=/contact/email-me,MD06,MD-06
  Michael,R.,Turner,,https://turner.house.gov/contact/email-me,OH10,OH-10
  Lauren,,Underwood,RN,https://underwood.house.gov/zip-code-lookup?form=/contact/email-me,IL14,IL-14
  Fred,,Upton,,https://upton.house.gov/contact/zipauth.htm,MI06,MI-06
  Jefferson,,Van Drew,DDS,https://vandrew.house.gov/zip-code-lookup?form=/contact/email-me,NJ02,NJ-02
  Juan,,Vargas,,https://vargas.house.gov/contact/legislative-comments,CA51,CA-51
  Marc,A.,Veasey,,https://veasey.house.gov/contact/email-me,TX33,TX-33
  Filemon,,Vela,,https://vela.house.gov/contact,TX34,TX-34
  Nydia,M.,Velázquez,,https://velazquez.house.gov/contact,NY07,NY-07
  Peter,J.,Visclosky,,https://visclosky.house.gov/contact-pete/email-pete,IN01,IN-01
  Ann,,Wagner,,https://wagner.house.gov/contact/email-me,MO02,MO-02
  Tim,,Walberg,,https://walberg.house.gov/contact/email,MI07,MI-07
  Greg,,Walden,,https://walden.house.gov/contact-greg/email-me,OR02,OR-02
  Mark,,Walker,,https://walker.house.gov/contact/email,NC06,NC-06
  Jackie,,Walorski,,https://walorski.house.gov/contact/email/,IN02,IN-02
  Michael,,Waltz,,https://waltz.house.gov/contact/,FL06,FL-06
  Debbie,,Wasserman Schultz,,https://wassermanschultz.house.gov/contact/,FL23,FL-23
  Maxine,,Waters,,https://waters.house.gov/contact/email,CA43,CA-43
  Steven,C.,Watkins,Jr.,https://watkins.house.gov/contact/email,KS02,KS-02
  Bonnie,,Watson Coleman,,https://watsoncoleman.house.gov/contact/,NJ12,NJ-12
  Randy,K.,Weber,Sr.,https://weber.house.gov/contact/,TX14,TX-14
  Daniel,,Webster,,https://webster.house.gov/email-me,FL11,FL-11
  Peter,,Welch,,https://welch.house.gov/contact,VT00,VT-00
  Brad,R.,Wenstrup,MD,https://wenstrup.house.gov/contact/,OH02,OH-02
  Bruce,,Westerman,,https://westerman.house.gov/contact/email,AR04,AR-04
  Jennifer,,Wexton,,https://wexton.house.gov/contact/,VA10,VA-10
  Susan,,Wild,,https://wild.house.gov/contact/email-me,PA07,PA-07
  Roger,,Williams,,https://williams.house.gov/contact/email-me,TX25,TX-25
  Frederica,S.,Wilson,,https://wilson.house.gov/contact/email-me,FL24,FL-24
  Joe,,Wilson,,https://joewilson.house.gov/contact/email-me,SC02,SC-02
  Robert,J.,Wittman,,https://wittman.house.gov/contact/,VA01,VA-01
  Steve,,Womack,,https://womack.house.gov/contact/,AR03,AR-03
  Rob,,Woodall,,https://woodall.house.gov/contact,GA07,GA-07
  Ron,,Wright,,https://wright.house.gov/zip-code-lookup?form=/contact/email-me,TX06,TX-06
  John,A.,Yarmuth,,https://yarmuth.house.gov/contact-john2/,KY03,KY-03
  Ted,S.,Yoho,,https://yoho.house.gov/contact/email-me,FL03,FL-03
  Don,,Young,,https://donyoung.house.gov/contact/,AK00,AK-00
  Lee,M.,Zeldin,,https://zeldin.house.gov/zip-code-lookup?form=/contact/email-me,NY01,NY-01`;

  const arr = reps.split("\n");

  var jsonObj = [];
  var headers = arr[0].split(",");
  for (var i = 1; i < arr.length; i++) {
    var data = arr[i].split(",");
    var obj = {};
    for (var j = 0; j < data.length; j++) {
      obj[headers[j].trim()] = data[j].trim();
    }
    jsonObj.push(obj);
  }
  let repArr = jsonObj.map((obj) => {
    return {
      name: `${obj.firstName} ${obj.middleName} ${obj.lastName} ${obj.suffix}`
        .replace(/ +(?= )/g, "")
        .replace(/"/g, ""),
      st_dis: obj.st_dis,
      form_url: obj.formUrl,
    };
  });
  return repArr;
};

export const repData = formatRepData();
